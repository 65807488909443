import React from "react";
import LandingPage from "./components/pages/LandingPage";
import MyContext from "./Context/Context";
function App() {
  return (
    <MyContext>
      <LandingPage />
    </MyContext>
  );
}

export default App;
