import React, { Component } from "react";
import Slider from "react-slick";
import { FaQuoteLeft, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
  return <div className="hidden" />;
}

export default class PreviousNextMethods extends Component {
  next = this.next.bind(this);
  previous = this.previous.bind(this);
  state = {
    carcoun: window.innerWidth >= 640 ? 3 : 1,
    cardots: window.innerWidth < 330 ? false : true,
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      speed: 2000,
      autoplaySpeed: 6000,
      nextArrow: <SamplePrevArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    const { ThemTextColor, ThemBGColor } = this.props.themeColor;
    const { ModeBgColor_100, ModeBorderColor_50 } = this.props.mode;
    return (
      <>
        <div className="">
          <div
            data-aos="zoom-in"
            data-aos-duration="500"
            className="max-sm:mt-[3rem] mx-auto relative flex items-center justify-center "
          >
            <Slider
              ref={(c) => (this.slider = c)}
              {...settings}
              className="mx-auto  max-sm:w-[26.5rem] w-[38rem]"
            >
              <div className="max-sm:mx-0 w-[38rem]  max-sm:h-[32rem] flex justify-center gap-[1.6rem]">
                <div
                  className={`mx-auto flex flex-col justify-between gap-[1rem] h-[26rem] max-sm:h-fit w-[32rem] max-sm:w-[26rem] p-[2rem] ${ModeBgColor_100} rounded-[.8rem] border-[1px] ${ModeBorderColor_50} `}
                >
                  <h2 className={`text-[2rem] ${ThemTextColor} `}>
                    <FaQuoteLeft />
                  </h2>
                  <div>
                    <p className="text-[1.1rem]">
                      Dhrumit Panchal excels in frontend development,
                      specializing in React JS, Next JS, Tailwind CSS,
                      Bootstrap, and C/C++. His design and animation skills,
                      quick learning, teamwork, and humility set him apart.
                      Dhrumit has made invaluable contributions to my projects.
                      Thank you, Dhrumit, for your exceptional collaboration!
                    </p>
                    <div className="mt-[.2rem] text-[1rem] font-semibold">
                      October 15,2023
                    </div>
                  </div>

                  <div className="mt-auto  flex gap-[1.5rem] items-center">
                    <div className="h-[4rem] w-[4rem] flex justify-center items-center rounded-full bg-slate-300 overflow-hidden">
                      <img
                        src="./images/profile-pic.webp"
                        alt=""
                        className="h-[4.8rem] w-[5rem] "
                      />
                    </div>
                    <div className="text-[1rem]">
                      <h2 className="font-bold">Yash soni</h2>
                      <h3>_</h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------------------------------------------------- */}

              <div className="max-sm:mx-0 w-[38rem]  max-sm:h-[32rem] flex justify-center gap-[1.6rem]">
                <div
                  className={`mx-auto flex flex-col justify-between gap-[1rem] h-[26rem] max-sm:h-fit w-[32rem] max-sm:w-[26rem] p-[2rem] ${ModeBgColor_100} rounded-[.8rem] border-[1px] ${ModeBorderColor_50}`}
                >
                  <h2 className={`text-[2rem] ${ThemTextColor} `}>
                    <FaQuoteLeft />
                  </h2>
                  <div>
                    <p className="text-[1.1rem]">
                      Dhrumit's exceptional frontend skills, design acumen, and
                      tech proficiency significantly enhance any project. His
                      commitment to staying current with trends ensures visually
                      stunning, user-friendly interfaces that align with modern
                      design principles. He's a standout developer for UI work.
                    </p>
                    <div className="mt-[.2rem] text-[1rem] font-semibold">
                      October 24,2023
                    </div>
                  </div>

                  <div className="mt-auto flex gap-[1.5rem] items-center">
                    <div className="h-[4rem] w-[4rem] flex justify-center items-center rounded-full bg-slate-300 overflow-hidden">
                      <img
                        src="./images/profile-pic.webp"
                        alt=""
                        className="h-[4.8rem] w-[5rem] "
                      />
                    </div>
                    <div className="text-[1rem]">
                      <h2 className="font-bold">Rakesh Meena</h2>
                      <h3>_</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* ------------------------------------------------- */}

              <div className="max-sm:mx-0 w-[38rem]  max-sm:h-[32rem] flex justify-center gap-[1.6rem]">
                <div
                  className={`mx-auto flex flex-col justify-between gap-[1rem] h-[26rem] max-sm:h-fit w-[32rem] max-sm:w-[26rem] p-[2rem] ${ModeBgColor_100} rounded-[.8rem] border-[1px] ${ModeBorderColor_50}`}
                >
                  <h2 className={`text-[2rem] ${ThemTextColor} `}>
                    <FaQuoteLeft />
                  </h2>
                  <div>
                    <p className="text-[1.1rem]">
                      Your outstanding work has significantly elevated our web
                      presence. The website looks amazing and functions
                      seamlessly, providing an excellent user experience. Your
                      attention to detail, creativity, and problem-solving
                      skills are invaluable. Keep up the fantastic work; you are
                      a crucial and greatly appreciated part of our team!
                    </p>
                    <div className="mt-[.2rem] text-[1rem] font-semibold">
                      jun 07,2024
                    </div>
                  </div>

                  <div className="mt-auto flex gap-[1.5rem] items-center">
                    <div className="h-[4rem] w-[4rem] flex justify-center items-center rounded-full bg-slate-300 overflow-hidden">
                      <img
                        src="./images/profile-pic.webp"
                        alt=""
                        className="h-[4.8rem] w-[5rem] "
                      />
                    </div>
                    <div className="text-[1rem]">
                      <h2 className="font-bold">Jainam Jain</h2>
                      <h3>_</h3>
                    </div>
                  </div>
                </div>
              </div>

              {/* ------------------------------------------------- */}
            </Slider>
            <div className="absolute flex justify-between items-center w-[60rem] -translate-y-[1rem] text-[1.5rem] text-[#fdf9ff]">
              <div
                onClick={this.previous}
                className={`flex justify-center items-center h-[2.8rem] w-[4.5rem] ${ThemBGColor} rounded-[1.5rem] `}
              >
                <FaArrowLeft />
              </div>
              <div
                onClick={this.next}
                className={`flex justify-center items-center h-[2.8rem] w-[4.5rem] ${ThemBGColor} rounded-[1.5rem] `}
              >
                <FaArrowRight />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
