import React, { useState, useEffect, useContext } from "react";
import AsideMenu from "../AsideMenu";
import SectionTitle from "../SectionTitle";
import CirLOderSet from "../CirLOderSet";
import WorkCard from "../WorkCard";
import AOS from "aos";
import "aos/dist/aos.css";
import projectData from "../projectData";
import PreviousNextMethods from "../TestiSlider";

import { Context } from "../../Context/Context";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
  FaEnvelope,
  FaCode,
  FaPaintBrush,
  FaDesktop,
  FaGithub,
  FaPaperPlane,
  FaSun,
  FaMoon,
} from "react-icons/fa";
import { useRef } from "react";

function LandingPage() {
  const [themeColorMenu, setThemeColorMenu] = useState(false);
  const { liteMode, setLiteMode, mode, theme, themeColor } =
    useContext(Context);
  console.log("context : " + liteMode);
  const [aboutTab, setAboutTab] = useState(1);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    AOS.init({
      mirror: true,
      once: false,
      offset: 0,
    });
  });

  let menuRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setThemeColorMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // for contact form
  useEffect(() => {
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbzPLIJUFjyQEePYxfpcZs0sZUFBi9ECk-iy0FxR6RIMNc0gkuWZT6Ehm1Eyq6dBzQ5_/exec";
    const form = document.forms["submit-to-google-sheet"];
    const msg = document.getElementsByClassName("msg");

    form.addEventListener("submit", (e) => {
      e.preventDefault();
      fetch(scriptURL, { method: "POST", body: new FormData(form) })
        .then((response) => {
          setTimeout(() => {
            setMsg("Msg sent 👍");
          }, 2000);
        })
        .catch((error) => {
          setTimeout(() => {
            setMsg("Msg not sent 😔");
          }, 2000);
        });
    });
    setMsg("");
  }, []);

  return (
    <div>
      <AsideMenu />
      <main>
        {/* ---------------- home section ----------------*/}
        <div
          className={` duration-500 transition-colors max-sm:left-0 absolute h-screen left-[19rem] top-0 right-0 select-none ${mode.ModeTextColor_900} ${mode.ModeBgColor_900}`}
        >
          <section
            id="home"
            className={`overflow-hidden max-sm:h-[93.1vh] h-screen w-full flex justify-between flex-col py-[2rem] px-[3.5rem] max-sm:px-[2rem] `}
          >
            <div
              ref={menuRef}
              className="fixed top-[6rem] right-[1.6rem] flex gap-[1.5rem] z-10 "
            >
              <div
                className={` ${
                  themeColorMenu ? "w-[16.5rem]" : "w-0 hidden"
                }  relative h-[6.2rem] mt-[4rem] py-[.7rem] px-[1rem] rounded-[.6rem] ${
                  mode.ModeBgColor_100
                } before:content-[""] before:absolute
          before:h-[1.2rem] before:w-[1.2rem] before:-right-[.3rem] before:top-[.7rem] before:rounded-[.1rem] before:rotate-[45deg] shadow-[0_1.4px_2px_rgba(0,0,0,0.3)] `}
              >
                <h2 className={`text-[1.1rem] ${mode.ModeTextColor_900}`}>
                  Theme color
                </h2>

                <div className="mt-[.6rem] flex justify-between">
                  <div
                    onClick={() => themeColor("red")}
                    className=" h-[2.5rem] w-[2.5rem] rounded-full bg-[#ec1839]"
                  ></div>
                  <div
                    onClick={() => themeColor("orange")}
                    className=" h-[2.5rem] w-[2.5rem] rounded-full bg-[#fa5b0f]"
                  ></div>
                  <div
                    onClick={() => themeColor("green")}
                    className=" h-[2.5rem] w-[2.5rem] rounded-full bg-[#37b182]"
                  ></div>
                  <div
                    onClick={() => themeColor("blue")}
                    className=" h-[2.5rem] w-[2.5rem] rounded-full bg-[#1854b4]"
                  ></div>
                  <div
                    onClick={() => themeColor("pink")}
                    className=" h-[2.5rem] w-[2.5rem] rounded-full bg-[#f021b2]"
                  ></div>
                </div>
              </div>
              <div className="flex flex-col gap-[1rem]">
                <div
                  onClick={() => setLiteMode(!liteMode)}
                  className={`flex justify-center items-center h-[2.8rem] w-[2.8rem] rounded-full shadow-[0_1.4px_2px_rgba(0,0,0,0.3)] ${mode.ModeBgColor_100}`}
                >
                  {liteMode ? (
                    <FaMoon className={`${theme.ThemTextColor}`} />
                  ) : (
                    <FaSun className={`${theme.ThemTextColor}`} />
                  )}
                </div>
                <div
                  onClick={() => setThemeColorMenu(!themeColorMenu)}
                  className={`flex justify-center items-center h-[2.8rem] w-[2.8rem] rounded-full shadow-[0_1.4px_2px_rgba(0,0,0,0.3)] ${mode.ModeBgColor_100}`}
                >
                  <FaPaintBrush className={`${theme.ThemTextColor}`} />
                </div>
              </div>
            </div>

            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex items-center "
            >
              <h2 className="inline text-[1.4rem] ">Follow me - </h2>
              <a
                target="_self"
                href="https://instagram.com/dhrumit__789?utm_source=qr&igshid=MzNlNGNkZWQ4Mg=="
                className={`ml-[.6rem] hover:translate-y-[-5px] hover:${theme.ThemTextColor} transition-transform duration-300 `}
              >
                <FaInstagram className="inline text-[1.5rem]" />
              </a>
              <a
                target="_self"
                href="https://www.facebook.com/dhrumitpanchal789?mibextid=ZbWKwL"
                className={`ml-[.6rem] hover:translate-y-[-5px] hover:${theme.ThemTextColor} transition-transform duration-300 `}
              >
                <FaFacebookSquare className="inline text-[1.5rem]" />
              </a>
              <a
                target="_self"
                href="https://github.com/DhrumitPanchal"
                className={`ml-[.6rem] hover:translate-y-[-5px] hover:${theme.ThemTextColor} transition-transform duration-300 `}
              >
                <FaGithub className="inline text-[1.5rem]" />
              </a>
              <a
                target="_self"
                href="https://www.linkedin.com/in/dhrumit-panchal-54b71b29b"
                className={`ml-[.6rem] hover:translate-y-[-5px] hover:${theme.ThemTextColor} transition-transform duration-300 `}
              >
                <FaLinkedinIn className="inline text-[1.5rem]" />
              </a>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className=" home-info flex justify-between flex-col text-[2.1rem] font-extrabold"
            >
              <h3 className="capitalize ">hello!</h3>
              <h2>
                my name is{" "}
                <span
                  className={`capitalize text-[2.1rem] font-extrabold ${theme.ThemTextColor}`}
                >
                  dhrumit panchal
                </span>
              </h2>
              <h2>
                I'm a{" "}
                <span
                  id="typingText"
                  className={`typingText ${theme.ThemTextColor}`}
                >
                  Web Developer
                </span>
              </h2>
              <p
                className={`mb-[4rem] text-[1.4rem] ${mode.ModeTextColor_900} font-normal w-[80%] max-sm:w-full`}
              >
                I’m a web developer and i love develop next generation products.
                My expertise is to create a good user experience and mobile
                friendly websites.
              </p>
              <div className="flex gap-[2rem]">
                <a
                  href="./Dhrumit-Resume.pdf"
                  download
                  className={`capitalize py-[.6rem] px-[2rem] text-[1.1rem] rounded-[3rem] font-medium ${theme.ThemBGColor} text-white `}
                >
                  resume
                </a>
                <a
                  href="#about"
                  className={`capitalize py-[.6rem] px-[2rem] text-[1.1rem] rounded-[3rem] font-medium ${theme.ThemBGColor} text-white `}
                >
                  about me
                </a>
              </div>
            </div>
            <div
              data-aos="fade-left"
              data-aos-duration="500"
              className="flex items-center gap-[2rem] "
            >
              <a
                href="https://wa.me/9725398019/?text=Hii"
                className="flex items-center"
              >
                <FaWhatsapp className=" text-[1.4rem]  inline mr-[.4rem] hover:translate-y-[-3px] transition-transform duration-300 " />
                <h2 className=" text-[1.4rem] ">Whatsapp</h2>
              </a>
              <a
                href="mailto:dhrumitpanchal789@gmail.com"
                className="flex items-center "
              >
                <FaEnvelope className=" text-[1.4rem] inline mr-[.4rem] hover:translate-y-[-3px] transition-transform duration-300 " />
                <h2 className=" text-[1.4rem] ">Email</h2>
              </a>
            </div>
          </section>
          {/* ---------------- about section ----------------*/}
          <section
            id="about"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden relative max-sm:h-fit h-screen w-full flex justify-between flex-col py-[2rem] px-[3.5rem] max-sm:px-[2rem]`}
          >
            <SectionTitle title={"About Me"} />

            <div className="max-sm:mt-[3rem] h-full flex justify-between items-center max-sm:flex-col max-sm:gap-[3rem]">
              <div
                data-aos="fade-right"
                data-aos-duration="500"
                className="h-[25rem] "
              >
                <img
                  alt="dhrumit panchal"
                  src="./images/dhrumitpanchal.jpg"
                  className="w-full h-full"
                />
              </div>

              <div
                data-aos="fade-left"
                data-aos-duration="500"
                className="max-sm:mr-0 basis-[60%] mr-[2rem] flex justify-between gap-[2rem] flex-col max-sm:text-center"
              >
                <h2 className="text-[2.1rem] font-bold">
                  I’m{" "}
                  <span className={`${theme.ThemTextColor}`}>
                    Dhrumit Panchal
                  </span>{" "}
                  a Web Developer , <br className="max-sm:hidden" />
                  Web Designer and Sketch Artist.
                </h2>
                <p className="text-[1.3rem] tracking-[.05rem]">
                  I am a skilled Full Stack Web Developer specializing in React
                  JS, Next JS, and the MERN stack, with a strong focus on
                  building responsive, user-friendly interfaces. Currently
                  expanding my expertise in React Native for mobile app
                  development, I am committed to continuous learning and
                  delivering innovative, scalable solutions. As a freelancer, I
                  have successfully completed numerous projects, consistently
                  meeting client expectations and adapting to evolving
                  technologies to drive impactful results.
                </p>
              </div>
            </div>
          </section>
          {/* ---------------- about section (page 2) ----------------*/}
          <div
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden max-sm:h-fit h-screen w-full flex justify-between items-center max-sm:flex-col gap-[8rem] max-sm:gap-[0rem] py-[2rem] px-[3.5rem] max-sm:px-[2rem]`}
          >
            <div
              data-aos="fade-up"
              data-aos-duration="500"
              className={`pr-[.8rem] py-[2rem] max-sm:border-0 border-r-[1.5px] ${mode.ModeBorderColor_50}`}
            >
              <ul className="mt-[1.4rem] max-sm:flex flex-wrap max-sm:justify-between">
                <li
                  onClick={() => setAboutTab(1)}
                  className={`${
                    aboutTab === 1 ? `${theme.ThemTextColor}` : ""
                  } mb-[1.4rem] block transition-colors duration-300 cursor-pointer`}
                >
                  <h2
                    className={`w-[11rem] text-[1.7rem] font-medium py-[.2rem] px-[.4rem] border-b-[1.5px]  ${mode.ModeBorderColor_50} flex`}
                  >
                    Front End
                  </h2>
                </li>
                <li
                  onClick={() => setAboutTab(2)}
                  className={`${
                    aboutTab === 2 ? `${theme.ThemTextColor}` : ""
                  } mb-[1.4rem] block transition-colors duration-300 cursor-pointer`}
                >
                  <h2
                    className={`w-[11rem] text-[1.7rem] font-medium py-[.2rem] px-[.4rem] border-b-[1.5px]  ${mode.ModeBorderColor_50} flex`}
                  >
                    Frameworks
                  </h2>
                </li>
                <li
                  onClick={() => setAboutTab(3)}
                  className={`${
                    aboutTab === 3 ? `${theme.ThemTextColor}` : ""
                  } mb-[1.4rem] block transition-colors duration-300 cursor-pointer`}
                >
                  <h2
                    className={`w-[11rem] text-[1.7rem] font-medium py-[.2rem] px-[.4rem] border-b-[1.5px]  ${mode.ModeBorderColor_50} flex`}
                  >
                    Libraries
                  </h2>
                </li>
                <li
                  onClick={() => setAboutTab(4)}
                  className={`${
                    aboutTab === 4 ? `${theme.ThemTextColor}` : ""
                  } mb-[1.4rem] block transition-colors duration-300 cursor-pointer`}
                >
                  <h2
                    className={`w-[11rem] text-[1.7rem] font-medium py-[.2rem] px-[.4rem] border-b-[1.5px]  ${mode.ModeBorderColor_50} flex`}
                  >
                    DataBase
                  </h2>
                </li>
                <li
                  onClick={() => setAboutTab(5)}
                  className={`${
                    aboutTab === 5 ? `${theme.ThemTextColor}` : ""
                  } mb-[1.4rem] block transition-colors duration-300 cursor-pointer`}
                >
                  <h2
                    className={`w-[11rem] text-[1.7rem] font-medium py-[.2rem] px-[.4rem] border-b-[1.5px]  ${mode.ModeBorderColor_50} flex`}
                  >
                    Back End
                  </h2>
                </li>
              </ul>
            </div>
            <CirLOderSet tab={aboutTab} />
          </div>
          {/* ---------------- service section ----------------*/}
          <section
            id="services"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden max-sm:h-fit h-screen w-full flex justify-between flex-col py-[2rem] px-[3.5rem] max-sm:px-[2rem]`}
          >
            <SectionTitle title={"services"} />

            <div className="relative max-sm:mt-[3rem] max-sm:h-fit h-full flex justify-center items-center  flex-col max-sm:gap-[3rem]">
              <h2
                data-aos="zoom-in"
                data-aos-duration="500"
                className={`absolute top-0 text-[2rem] font-bold ${theme.ThemTextColor} capitalize`}
              >
                What I offer
              </h2>

              <div className="flex gap-[2.5rem] max-sm:gap-[2.5rem] max-sm:flex-col max-sm:pt-[6rem] ">
                <div
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`h-[24rem] w-[22rem] ${mode.ModeBgColor_100} p-[2rem] rounded-[1rem] flex justify-between items-center flex-col border-[1px] ${mode.ModeBorderColor_50}`}
                >
                  <FaCode
                    className={`text-[5rem] mt-[1.2rem] ${theme.ThemTextColor}`}
                  />
                  <h2 className="text-[2rem] font-semibold">Web developer</h2>
                  <p className="text-[1.2rem] ">
                    I'm a skilled web developer specializing in creating
                    immersive online experiences. With a focus on responsive
                    design.
                  </p>
                </div>

                <div
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`h-[24rem] w-[22rem] ${mode.ModeBgColor_100} p-[2rem] rounded-[1rem] flex justify-between items-center flex-col border-[1px] ${mode.ModeBorderColor_50}`}
                >
                  <FaDesktop
                    className={`text-[4rem] mt-[1.2rem] ${theme.ThemTextColor}`}
                  />
                  <h2 className="text-[2rem] font-semibold">Web designer</h2>
                  <p className="text-[1.2rem] ">
                    My UI design skills shine through in my ability to create
                    visually stunning, user-friendly interfaces that captivate
                    and engage.{" "}
                  </p>
                </div>

                <div
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`h-[24rem] w-[22rem] ${mode.ModeBgColor_100} p-[2rem] rounded-[1rem] flex justify-between items-center flex-col border-[1px] ${mode.ModeBorderColor_50}`}
                >
                  <FaPaintBrush
                    className={`text-[4rem] mt-[1.2rem] ${theme.ThemTextColor}`}
                  />
                  <h2 className="text-[2rem] font-semibold">sketching</h2>
                  <p className="text-[1.2rem] ">
                    Sketching is my passion. With each stroke, I breathe life
                    into my imagination, creating intricate worlds and emotions.{" "}
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* ---------------- work section ----------------*/}
          <section
            id="work"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden max-sm:h-fit h-screen w-full flex justify-between flex-col py-[2rem] px-[2.5rem] max-sm:px-[2rem]`}
          >
            <div className="flex items-center justify-between pr-[2rem]">
              <SectionTitle title={"works"} />
            </div>

            <div className="flex justify-start flex-wrap gap-[1.5rem] h-full w-full py-[2rem]">
              {projectData.map((pro) => {
                return (
                  <WorkCard
                    title={pro.title}
                    image={pro.image}
                    link={pro.link}
                  />
                );
              })}
            </div>
          </section>
          {/* ---------------- testimonials ----------------*/}

          <section
            id="work"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden max-sm:h-fit h-screen w-full flex justify-between flex-col py-[2rem] px-[2.5rem] max-sm:px-[2rem]`}
          >
            <div
              data-aos="zoom-in"
              data-aos-duration="500"
              className="mt-[1rem] flex justify-center items-center flex-col "
            >
              <h4 className={`${theme.ThemTextColor} font-semibold leading-4`}>
                My Clients Say
              </h4>
              <h1 className="text-[2rem] font-bold">Testimonials</h1>
            </div>
            <PreviousNextMethods themeColor={theme} mode={mode} />
          </section>
          {/* -------------- contact section --------------- */}
          <section
            id="contact"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} overflow-hidden max-sm:h-fit h-screen w-full flex justify-between flex-col py-[2rem] px-[3.5rem] max-sm:px-[2rem]`}
          >
            <SectionTitle title={"contact"} theme={theme} />

            <div className="max-sm:mt-[2rem] h-full w-full flex justify-center flex-col items-center gap-[4rem]">
              <div
                data-aos="zoom-in"
                data-aos-duration="500"
                className="flex justify-center flex-col items-center gap-[.1rem]"
              >
                <h2
                  className={`text-[1.8rem] ${theme.ThemTextColor} font-bold capitalize `}
                >
                  Have You Any Questions?
                </h2>
                <h4 className="text-[1.3rem] font-semibold">
                  I'M AT YOUR SERVICE
                </h4>
              </div>

              <div className="mb-[4rem] flex justify-between items-center  gap-[6rem] max-sm:gap-[3rem] max-sm:justify-center text-[3rem] flex-wrap">
                <a
                  target="_self"
                  href="https://www.instagram.com/dhrumit_789/?igsh=MWUzZTB4dHRsYmwyNg%3D%3D"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`cursor-pointer flex justify-center items-center flex-col gap-[.2rem] h-[8rem] w-[8rem] ${mode.ModeBgColor_100} rounded-[1rem] border-[1px] ${mode.ModeBorderColor_50} ${theme.ThemHoverText} duration-300 transition-colors `}
                >
                  <FaInstagram />
                  <h2 className="text-[1rem] font-medium">instagram</h2>
                </a>
                <a
                  target="_self"
                  href="https://github.com/DhrumitPanchal"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`cursor-pointer flex justify-center items-center flex-col gap-[.2rem] h-[8rem] w-[8rem] ${mode.ModeBgColor_100} rounded-[1rem] border-[1px] ${mode.ModeBorderColor_50} ${theme.ThemHoverText} duration-300 transition-colors `}
                >
                  <FaGithub />
                  <h2 className="text-[1rem] font-medium">github</h2>
                </a>
                <a
                  target="_self"
                  href="https://www.linkedin.com/in/dhrumit-panchal-54b71b29b"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`cursor-pointer flex justify-center items-center flex-col gap-[.2rem] h-[8rem] w-[8rem] ${mode.ModeBgColor_100} rounded-[1rem] border-[1px] ${mode.ModeBorderColor_50} ${theme.ThemHoverText} duration-300 transition-colors `}
                >
                  <FaLinkedinIn />
                  <h2 className="text-[1rem] font-medium">linkedin</h2>
                </a>
                <a
                  href="mailto:dhrumitpanchal789@gmail.com"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className={`cursor-pointer flex justify-center items-center flex-col gap-[.2rem] h-[8rem] w-[8rem] ${mode.ModeBgColor_100} rounded-[1rem] border-[1px] ${mode.ModeBorderColor_50} ${theme.ThemHoverText} duration-300 transition-colors `}
                >
                  <FaEnvelope />
                  <h2 className="text-[1rem] font-medium">email</h2>
                </a>
              </div>
            </div>
          </section>
          {/* -------------- contact section page 2 --------------- */}
          <section
            id="contact"
            className={`${mode.ModeTextColor_900} ${mode.ModeBgColor_900} relative overflow-hidden max-sm:h-fit h-screen w-full flex justify-center gap-[6rem] flex-col py-[2rem] px-[3.5rem] max-sm:px-[2rem]`}
          >
            <div
              data-aos="zoom-in"
              data-aos-duration="500"
              className="flex justify-center flex-col items-center gap-[.1rem]"
            >
              <h2
                className={`text-[2.2rem] ${theme.ThemTextColor} font-bold capitalize `}
              >
                Send Me An Message
              </h2>
              <h4 className="text-[1.3rem] font-semibold">
                I’M VERY RESPONSIVE TO MESSAGES
              </h4>
            </div>

            <div data-aos="zoom-in" data-aos-duration="500">
              <form
                name="submit-to-google-sheet"
                className="flex flex-col gap-[1rem] px-[2rem] max-sm:px-0"
              >
                <div className="flex max-sm:flex-col w-full gap-[1.5rem] max-sm:gap-[1rem]">
                  <input
                    name="NAME"
                    type="text"
                    placeholder="Name"
                    required
                    className={`h-[3.2rem] w-full px-[1rem] rounded-[.5rem] border-[1px] ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50}`}
                  />
                  <input
                    name="EMAIL"
                    type="email"
                    placeholder="Email"
                    required
                    className={`h-[3.2rem] w-full px-[1rem] rounded-[.5rem] border-[1px] ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50}`}
                  />
                </div>
                <input
                  name="SUBJECT"
                  type="text"
                  placeholder="Subject"
                  required
                  className={`h-[3.2rem] w-full px-[1rem] rounded-[.5rem] border-[1px] ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50}`}
                />
                <textarea
                  name="MESSAGE"
                  cols="30"
                  rows="10"
                  placeholder="Message"
                  required
                  className={`h-[9rem] w-full p-[1rem] rounded-[1rem] border-[1px] ${mode.ModeBgColor_100} ${mode.ModeBorderColor_50}`}
                />
                <div className="flex items-center justify-between h-fit">
                  <button
                    type="submit"
                    className={` flex justify-center items-center gap-[1rem] w-[12rem] h-[3.2rem] capitalize text-[1.1rem] rounded-[1rem] font-medium ${theme.ThemBGColor}  text-[#fdf9ff] `}
                  >
                    <FaPaperPlane className="text-[1.5rem]" />
                    Send Message
                  </button>
                  <div>
                    <span className={`relative  msg ${theme.ThemTextColor}`}>
                      {msg}
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </section>
          {/* -----------end----------- */}
          <div
            className={`h-[2.6rem] max-sm:h-[4rem] flex justify-center items-center text-[1.1rem] ${mode.ModeTextColor_700}  ${mode.ModeTextColor_900}   ${theme.ThemBGColor}`}
          >
            Copyright © 2023 by Dhrumit | All Rights Reserved
          </div>
        </div>
      </main>
    </div>
  );
}

export default LandingPage;
