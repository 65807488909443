import React, { useContext } from "react";
import { Context } from "../Context/Context";
function SectionTitle({ title }) {
  const { theme } = useContext(Context);
  const ThemBGColor = theme.ThemBGColor;
  return (
    <div
      id="section-title"
      data-aos="fade-right"
      data-aos-duration="500"
      className=" flex flex-col mt-[2rem] mb-[2rem]"
    >
      <h2 className="text-[1.7rem] font-extrabold capitalize">{title}</h2>
      <div
        className={`h-[.4rem] w-[6rem] ${ThemBGColor}  rounded-[.4rem]`}
      ></div>
      <div
        className={`mt-[.3rem] h-[.4rem] w-[4rem] ${ThemBGColor} rounded-[.4rem]`}
      ></div>
    </div>
  );
}

export default SectionTitle;
