import React, { useContext } from "react";
import { Context } from "../Context/Context";
function WorkCard({ title, image, link }) {
  const { theme, mode } = useContext(Context);
  const { ThemTextColor } = theme;
  const { ModeBgColor_100, ModeBorderColor_50 } = mode;

  return (
    <div
      data-aos="zoom-in"
      data-aos-duration="500"
      className={`flex justify-between flex-col p-[1rem] h-[16rem] w-[17rem] max-sm:h-fit max-sm:max-w-[34rem] max-sm:w-full rounded-[.8rem] ${ModeBgColor_100} border-[1px] ${ModeBorderColor_50}`}
    >
      <div className="w-full h-auto">
        <img
          src={image}
          className={`h-full w-full ${ModeBorderColor_50} border-[1px] rounded-[.6rem] `}
          alt={title}
        />
      </div>
      <h2 className="mt-[.4rem] leading-[1.4rem] text-[1.2rem] font-semibold capitalize">
        {title}
      </h2>
      <a href={link} target="_blank" className={`${ThemTextColor} text-[1rem]`}>
        view..
      </a>
    </div>
  );
}

export default WorkCard;
